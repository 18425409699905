var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"position-content-container"},[(!_vm.showIframe)?_c('div',{staticClass:"position"},[_c('div',{staticClass:"position-header"},[_c('div',{staticClass:"slots-path"},[_c('div',{staticClass:"slot"},[_c('div',{staticClass:"path active"},[_vm._v(" "+_vm._s(_vm.$t('LiveCasino.140'))+" "+_vm._s(_vm.categoryChoose === (" / " + (_vm.$t('Message.266'))) ? '' : _vm.categoryChoose)+" ")])])]),_c('div',{staticClass:"select"},[_c('div',{staticClass:"select-trigger",on:{"click":function($event){_vm.showListChooseGame = !_vm.showListChooseGame}}},[_c('div',{staticClass:"provider-select-label provider-label"},[_vm._v(" "+_vm._s(_vm.$t('LiveCasino.140'))+" ")]),_vm._m(0)]),(_vm.showListChooseGame)?_c('div',{staticClass:"listTypeGame"},_vm._l((_vm.categoryGame),function(category,index){return _c('p',{key:index,class:_vm.showGameDisplay === index + 1 ? 'activeType' : '',on:{"click":function($event){_vm.showGameDisplay = index + 1;
              _vm.categoryChoose = " / " + (category.name);
              _vm.getListGameByCategory(category.key);
              _vm.categoryKeyChoose = category.key;}}},[_vm._v(" "+_vm._s(category.name === _vm.$t('Message.266') ? _vm.$t('LiveCasino.140') : category.name)+" "),_c('span',{class:_vm.showGameDisplay === index + 1 ? 'active' : ''},[_vm._v(_vm._s(_vm.showGameDisplay === index + 1 ? '' : _vm.getLengthListGameByCategory(category.key)))])])}),0):_vm._e()])]),_c('div',{staticClass:"position-content"},[(_vm.showGameDisplay === 1 && _vm.wm555 && _vm.wm555.length > 0)?_c('div',{staticClass:"game-good"},[_c('div',{staticClass:"game-good-title"},[_c('div',{staticClass:"tit-hot"},[_c('i',{staticClass:"fab fa-hotjar"}),_c('div',{staticClass:"cont"},[_vm._v("wm555")]),(_vm.wm555 && _vm.wm555.length > 0)?_c('span',{staticClass:"total"},[_vm._v(_vm._s(_vm.wm555.length))]):_vm._e()]),(
              (_vm.wm555 &&
                _vm.wm555.length > 0 &&
                _vm.widthLiveCasino > 1599 &&
                _vm.wm555.length > 6) ||
                (_vm.widthLiveCasino < 1599 && _vm.wm555.length > 5) ||
                (_vm.widthLiveCasino < 1399 && _vm.wm555.length > 4) ||
                (_vm.widthLiveCasino < 1099 && _vm.wm555.length > 3) ||
                (_vm.widthLiveCasino < 991 && _vm.wm555.length > 4) ||
                (_vm.widthLiveCasino < 767 && _vm.wm555.length > 3) ||
                (_vm.widthLiveCasino < 480 && _vm.wm555.length > 2)
            )?_c('div',{staticClass:"page-wrap"},[_c('button',{staticClass:"prev-button",on:{"click":function($event){return _vm.$refs.gameHot.previous()}}},[_c('i',{staticClass:"fas fa-chevron-left"})]),_c('button',{staticClass:"next-button",on:{"click":function($event){return _vm.$refs.gameHot.next()}}},[_c('i',{staticClass:"fas fa-chevron-right"})])]):_vm._e()]),_c('div',{staticClass:"game-good-slide"},[_c('vueper-slides',{ref:"gameHot",staticClass:"no-shadow",attrs:{"visible-slides":6,"slide-multiple":"","gap":2,"slide-ratio":1 / 4,"arrows":false,"bullets":false,"touchable":false,"breakpoints":{
              1599: { visibleSlides: 5, slideMultiple: 5 },
              1399: { visibleSlides: 4, slideMultiple: 4 },
              1099: { visibleSlides: 3, slideMultiple: 3 },
              991: { visibleSlides: 4, slideMultiple: 4 },
              767: { visibleSlides: 3, slideMultiple: 3 },
              480: { visibleSlides: 2, slideMultiple: 2, gap: '2' },
            }}},_vm._l((_vm.wm555),function(item,i){return _c('vueper-slide',{key:i,scopedSlots:_vm._u([{key:"content",fn:function(){return [_c('ItemGame',{attrs:{"item":item},on:{"chooseGameGetUrl":_vm.chooseGameGetUrl}})]},proxy:true}],null,true)})}),1)],1)]):_vm._e(),(_vm.showGameDisplay === 1 && _vm.agin && _vm.agin.length > 0)?_c('div',{staticClass:"game-good"},[_c('div',{staticClass:"game-good-title"},[_c('div',{staticClass:"tit-hot"},[_c('i',{staticClass:"fas fa-anchor"}),_c('div',{staticClass:"cont"},[_vm._v("Agin")]),(_vm.agin && _vm.agin.length > 0)?_c('span',{staticClass:"total"},[_vm._v(_vm._s(_vm.agin.length))]):_vm._e()]),(
              (_vm.agin &&
                _vm.agin.length > 0 &&
                _vm.widthLiveCasino > 1599 &&
                _vm.agin.length > 6) ||
                (_vm.widthLiveCasino < 1599 && _vm.agin.length > 5) ||
                (_vm.widthLiveCasino < 1399 && _vm.agin.length > 4) ||
                (_vm.widthLiveCasino < 1099 && _vm.agin.length > 3) ||
                (_vm.widthLiveCasino < 991 && _vm.agin.length > 4) ||
                (_vm.widthLiveCasino < 767 && _vm.agin.length > 3) ||
                (_vm.widthLiveCasino < 480 && _vm.agin.length > 2)
            )?_c('div',{staticClass:"page-wrap"},[_c('button',{staticClass:"prev-button",on:{"click":function($event){return _vm.$refs.agin.previous()}}},[_c('i',{staticClass:"fas fa-chevron-left"})]),_c('button',{staticClass:"next-button",on:{"click":function($event){return _vm.$refs.agin.next()}}},[_c('i',{staticClass:"fas fa-chevron-right"})])]):_vm._e()]),_c('div',{staticClass:"game-good-slide"},[_c('vueper-slides',{ref:"agin",staticClass:"no-shadow",attrs:{"visible-slides":6,"slide-multiple":"","gap":2,"slide-ratio":1 / 4,"arrows":false,"bullets":false,"touchable":false,"breakpoints":{
              1599: { visibleSlides: 5, slideMultiple: 5 },
              1399: { visibleSlides: 4, slideMultiple: 4 },
              1099: { visibleSlides: 3, slideMultiple: 3 },
              991: { visibleSlides: 4, slideMultiple: 4 },
              767: { visibleSlides: 3, slideMultiple: 3 },
              480: { visibleSlides: 2, slideMultiple: 2, gap: '2' },
            }}},_vm._l((_vm.agin),function(item,i){return _c('vueper-slide',{key:i,scopedSlots:_vm._u([{key:"content",fn:function(){return [_c('ItemGame',{attrs:{"item":item,"type":'live-casino-item'},on:{"chooseGameGetUrl":_vm.chooseGameGetUrl}})]},proxy:true}],null,true)})}),1)],1)]):_vm._e(),(_vm.showGameDisplay === 1 && _vm.evolution && _vm.evolution.length > 0)?_c('div',{staticClass:"game-good"},[_c('div',{staticClass:"game-good-title"},[_c('div',{staticClass:"tit-hot"},[_c('i',{staticClass:"fas fa-anchor"}),_c('div',{staticClass:"cont"},[_vm._v("Evolution")]),(_vm.evolution && _vm.evolution.length > 0)?_c('span',{staticClass:"total"},[_vm._v(_vm._s(_vm.evolution.length))]):_vm._e()]),(
              (_vm.evolution &&
                _vm.evolution.length > 0 &&
                _vm.widthLiveCasino > 1599 &&
                _vm.evolution.length > 6) ||
                (_vm.widthLiveCasino < 1599 && _vm.evolution.length > 5) ||
                (_vm.widthLiveCasino < 1399 && _vm.evolution.length > 4) ||
                (_vm.widthLiveCasino < 1099 && _vm.evolution.length > 3) ||
                (_vm.widthLiveCasino < 991 && _vm.evolution.length > 4) ||
                (_vm.widthLiveCasino < 767 && _vm.evolution.length > 3) ||
                (_vm.widthLiveCasino < 480 && _vm.evolution.length > 2)
            )?_c('div',{staticClass:"page-wrap"},[_c('button',{staticClass:"prev-button",on:{"click":function($event){return _vm.$refs.evolution.previous()}}},[_c('i',{staticClass:"fas fa-chevron-left"})]),_c('button',{staticClass:"next-button",on:{"click":function($event){return _vm.$refs.evolution.next()}}},[_c('i',{staticClass:"fas fa-chevron-right"})])]):_vm._e()]),_c('div',{staticClass:"game-good-slide"},[_c('vueper-slides',{ref:"evolution",staticClass:"no-shadow",attrs:{"visible-slides":6,"slide-multiple":"","gap":2,"slide-ratio":1 / 4,"arrows":false,"bullets":false,"touchable":false,"breakpoints":{
              1599: { visibleSlides: 5, slideMultiple: 5 },
              1399: { visibleSlides: 4, slideMultiple: 4 },
              1099: { visibleSlides: 3, slideMultiple: 3 },
              991: { visibleSlides: 4, slideMultiple: 4 },
              767: { visibleSlides: 3, slideMultiple: 3 },
              480: { visibleSlides: 2, slideMultiple: 2, gap: '2' },
            }}},_vm._l((_vm.evolution),function(item,i){return _c('vueper-slide',{key:i,scopedSlots:_vm._u([{key:"content",fn:function(){return [_c('ItemGame',{attrs:{"item":item,"type":'live-casino-item'},on:{"chooseGameGetUrl":_vm.chooseGameGetUrl}})]},proxy:true}],null,true)})}),1)],1)]):_vm._e(),(_vm.showGameDisplay === 1 && _vm.sky_game && _vm.sky_game.length > 0)?_c('div',{staticClass:"game-good"},[_c('div',{staticClass:"game-good-title"},[_c('div',{staticClass:"tit-new"},[_c('i',{staticClass:"fab fa-galactic-republic"}),_c('div',{staticClass:"cont"},[_vm._v("SKY GAME")]),(_vm.sky_game && _vm.sky_game.length > 0)?_c('span',{staticClass:"total"},[_vm._v(_vm._s(_vm.sky_game.length))]):_vm._e()]),(
              (_vm.sky_game &&
                _vm.sky_game.length > 0 &&
                _vm.widthLiveCasino > 1599 &&
                _vm.sky_game.length > 6) ||
                (_vm.widthLiveCasino < 1599 && _vm.sky_game.length > 5) ||
                (_vm.widthLiveCasino < 1399 && _vm.sky_game.length > 4) ||
                (_vm.widthLiveCasino < 1099 && _vm.sky_game.length > 3) ||
                (_vm.widthLiveCasino < 991 && _vm.sky_game.length > 4) ||
                (_vm.widthLiveCasino < 767 && _vm.sky_game.length > 3) ||
                (_vm.widthLiveCasino < 480 && _vm.sky_game.length > 2)
            )?_c('div',{staticClass:"page-wrap"},[_c('button',{staticClass:"prev-button",on:{"click":function($event){return _vm.$refs.gameNew.previous()}}},[_c('i',{staticClass:"fas fa-chevron-left"})]),_c('button',{staticClass:"next-button",on:{"click":function($event){return _vm.$refs.gameNew.next()}}},[_c('i',{staticClass:"fas fa-chevron-right"})])]):_vm._e()]),_c('div',{staticClass:"game-good-slide"},[_c('vueper-slides',{ref:"gameNew",staticClass:"no-shadow",attrs:{"visible-slides":6,"slide-multiple":"","gap":2,"slide-ratio":1 / 4,"arrows":false,"bullets":false,"touchable":false,"breakpoints":{
              1599: { visibleSlides: 5, slideMultiple: 5 },
              1399: { visibleSlides: 4, slideMultiple: 4 },
              1099: { visibleSlides: 3, slideMultiple: 3 },
              991: { visibleSlides: 4, slideMultiple: 4 },
              767: { visibleSlides: 3, slideMultiple: 3 },
              480: { visibleSlides: 2, slideMultiple: 2, gap: '2' },
            }}},_vm._l((_vm.sky_game),function(item,i){return _c('vueper-slide',{key:i,scopedSlots:_vm._u([{key:"content",fn:function(){return [_c('ItemGame',{attrs:{"item":item},on:{"chooseGameGetUrl":_vm.chooseGameGetUrl}})]},proxy:true}],null,true)})}),1)],1)]):_vm._e(),(false)?[(_vm.showGameDisplay === 1 && _vm.sa_game && _vm.sa_game.length > 0)?_c('div',{staticClass:"game-good"},[_c('div',{staticClass:"game-good-title"},[_c('div',{staticClass:"tit"},[_c('i',{staticClass:"fab fa-galactic-republic"}),_c('div',{staticClass:"cont"},[_vm._v("SA GAME")]),(_vm.sa_game && _vm.sa_game.length > 0)?_c('span',{staticClass:"total"},[_vm._v(_vm._s(_vm.sa_game.length))]):_vm._e()]),(
                (_vm.sa_game &&
                  _vm.sa_game.length > 0 &&
                  _vm.widthLiveCasino > 1599 &&
                  _vm.sa_game.length > 6) ||
                  (_vm.widthLiveCasino < 1599 && _vm.sa_game.length > 5) ||
                  (_vm.widthLiveCasino < 1399 && _vm.sa_game.length > 4) ||
                  (_vm.widthLiveCasino < 1099 && _vm.sa_game.length > 3) ||
                  (_vm.widthLiveCasino < 991 && _vm.sa_game.length > 4) ||
                  (_vm.widthLiveCasino < 767 && _vm.sa_game.length > 3) ||
                  (_vm.widthLiveCasino < 480 && _vm.sa_game.length > 2)
              )?_c('div',{staticClass:"page-wrap"},[_c('button',{staticClass:"prev-button",on:{"click":function($event){return _vm.$refs.gameGood.previous()}}},[_c('i',{staticClass:"fas fa-chevron-left"})]),_c('button',{staticClass:"next-button",on:{"click":function($event){return _vm.$refs.gameGood.next()}}},[_c('i',{staticClass:"fas fa-chevron-right"})])]):_vm._e()]),_c('div',{staticClass:"game-good-slide"},[_c('vueper-slides',{ref:"gameGood",staticClass:"no-shadow",attrs:{"visible-slides":6,"slide-multiple":"","gap":2,"slide-ratio":1 / 4,"arrows":false,"bullets":false,"touchable":false,"breakpoints":{
                1599: { visibleSlides: 5, slideMultiple: 5 },
                1399: { visibleSlides: 4, slideMultiple: 4 },
                1099: { visibleSlides: 3, slideMultiple: 3 },
                991: { visibleSlides: 4, slideMultiple: 4 },
                767: { visibleSlides: 3, slideMultiple: 3 },
                480: { visibleSlides: 2, slideMultiple: 2, gap: '2' },
              }}},_vm._l((_vm.sa_game),function(item,i){return _c('vueper-slide',{key:i,scopedSlots:_vm._u([{key:"content",fn:function(){return [_c('ItemGame',{attrs:{"item":item},on:{"chooseGameGetUrl":_vm.chooseGameGetUrl}})]},proxy:true}],null,true)})}),1)],1)]):_vm._e()]:_vm._e(),_c('div',{staticClass:"gameList"},[_c('div',{staticClass:"gameList-title"},[_c('div',{staticClass:"tit"},[_c('i',{staticClass:"fab fa-d-and-d"}),_c('div',{staticClass:"cont"},[_vm._v(" "+_vm._s(_vm.categoryKeyChoose === 'sa_game' ? 'SA GAME' : _vm.categoryKeyChoose === 'sky_game' ? 'SKY GAME' : _vm.categoryKeyChoose === 'agin' ? 'Agin' : _vm.categoryKeyChoose === 'wm555' ? 'wm555' : _vm.categoryKeyChoose === 'Evo' ? 'Evolution' : _vm.$t('Message.275'))+" ")]),_c('span',{staticClass:"total"},[_vm._v(" "+_vm._s(_vm.getLengthListGameByCategory(_vm.categoryKeyChoose))+" ")])])]),(
            _vm.listGame.game &&
              _vm.listGame.game.all &&
              _vm.listGame.game.all.length > 0 &&
              _vm.listGameShow
          )?_c('div',{staticClass:"gameList-list"},[_vm._l((_vm.listGameShow),function(item,i){return _c('div',{key:i,staticClass:"gameItem"},[_c('ItemGame',{attrs:{"item":item,"type":'live-casino-all'},on:{"chooseGameGetUrl":_vm.chooseGameGetUrl}})],1)}),_c('div',{staticClass:"gameList-btnMore-box"},[_c('div',{staticClass:"gameList-btnMore"},[_c('div',{staticClass:"btnMore-info"},[_c('span',{staticClass:"current mr-1"},[_vm._v(_vm._s(_vm.listGameShow && _vm.listGameShow.length > 0 ? _vm.listGameShow.length : 0)+" ")]),_vm._v("/"),_c('span',{staticClass:"ml-1 text-white"},[_vm._v(" "+_vm._s(_vm.getLengthListGameByCategory(_vm.categoryKeyChoose)))])]),(
                  _vm.getLengthListGameByCategory(_vm.categoryKeyChoose) >
                    _vm.listGameShow.length
                )?_c('button',{staticClass:"btnMore-load",on:{"click":function($event){_vm.loadMore(_vm.returnListGameByCategory(_vm.categoryKeyChoose))}}},[_c('div',{staticClass:"button-inner"},[_vm._v(_vm._s(_vm.$t('LiveCasino.141')))])]):_vm._e()])])],2):_vm._e()])],2)]):_vm._e()])}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"arrow"},[_c('i',{staticClass:"fas fa-chevron-right"})])}]

export { render, staticRenderFns }