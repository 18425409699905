<template>
  <div class="position-content-container">
    <div class="position" v-if="!showIframe">
      <div class="position-header">
        <div class="slots-path">
          <div class="slot">
            <div class="path active">
              {{ $t('LiveCasino.140') }}
              {{
                categoryChoose === ` / ${$t('Message.266')}`
                  ? ''
                  : categoryChoose
              }}
            </div>
          </div>
        </div>
        <div class="select">
          <div
            class="select-trigger"
            @click="showListChooseGame = !showListChooseGame"
          >
            <div class="provider-select-label provider-label">
              {{ $t('LiveCasino.140') }}
            </div>
            <div class="arrow">
              <i class="fas fa-chevron-right"></i>
            </div>
          </div>
          <div class="listTypeGame" v-if="showListChooseGame">
            <p
              :class="showGameDisplay === index + 1 ? 'activeType' : ''"
              v-for="(category, index) in categoryGame"
              :key="index"
              @click="
                showGameDisplay = index + 1;
                categoryChoose = ` / ${category.name}`;
                getListGameByCategory(category.key);
                categoryKeyChoose = category.key;
              "
            >
              {{
                category.name === $t('Message.266')
                  ? $t('LiveCasino.140')
                  : category.name
              }}
              <span :class="showGameDisplay === index + 1 ? 'active' : ''">{{
                showGameDisplay === index + 1
                  ? ''
                  : getLengthListGameByCategory(category.key)
              }}</span>
            </p>
          </div>
        </div>
      </div>
      <div class="position-content">
        <!-- wm555 -->
        <div
          class="game-good"
          v-if="showGameDisplay === 1 && wm555 && wm555.length > 0"
        >
          <div class="game-good-title">
            <div class="tit-hot">
              <i class="fab fa-hotjar"></i>
              <div class="cont">wm555</div>
              <span class="total" v-if="wm555 && wm555.length > 0">{{
                wm555.length
              }}</span>
            </div>
            <div
              class="page-wrap"
              v-if="
                (wm555 &&
                  wm555.length > 0 &&
                  widthLiveCasino > 1599 &&
                  wm555.length > 6) ||
                  (widthLiveCasino < 1599 && wm555.length > 5) ||
                  (widthLiveCasino < 1399 && wm555.length > 4) ||
                  (widthLiveCasino < 1099 && wm555.length > 3) ||
                  (widthLiveCasino < 991 && wm555.length > 4) ||
                  (widthLiveCasino < 767 && wm555.length > 3) ||
                  (widthLiveCasino < 480 && wm555.length > 2)
              "
            >
              <button @click="$refs.gameHot.previous()" class="prev-button">
                <i class="fas fa-chevron-left"></i>
              </button>
              <button @click="$refs.gameHot.next()" class="next-button">
                <i class="fas fa-chevron-right"></i>
              </button>
            </div>
          </div>
          <div class="game-good-slide">
            <vueper-slides
              class="no-shadow"
              :visible-slides="6"
              slide-multiple
              :gap="2"
              :slide-ratio="1 / 4"
              :arrows="false"
              :bullets="false"
              :touchable="false"
              ref="gameHot"
              :breakpoints="{
                1599: { visibleSlides: 5, slideMultiple: 5 },
                1399: { visibleSlides: 4, slideMultiple: 4 },
                1099: { visibleSlides: 3, slideMultiple: 3 },
                991: { visibleSlides: 4, slideMultiple: 4 },
                767: { visibleSlides: 3, slideMultiple: 3 },
                480: { visibleSlides: 2, slideMultiple: 2, gap: '2' },
              }"
            >
              <vueper-slide v-for="(item, i) in wm555" :key="i">
                <template #content>
                  <ItemGame :item="item" @chooseGameGetUrl="chooseGameGetUrl" />
                </template>
              </vueper-slide>
            </vueper-slides>
          </div>
        </div>
        <!-- agin -->
        <div
          class="game-good"
          v-if="showGameDisplay === 1 && agin && agin.length > 0"
        >
          <div class="game-good-title">
            <div class="tit-hot">
              <i class="fas fa-anchor"></i>
              <div class="cont">Agin</div>
              <span class="total" v-if="agin && agin.length > 0">{{
                agin.length
              }}</span>
            </div>
            <div
              class="page-wrap"
              v-if="
                (agin &&
                  agin.length > 0 &&
                  widthLiveCasino > 1599 &&
                  agin.length > 6) ||
                  (widthLiveCasino < 1599 && agin.length > 5) ||
                  (widthLiveCasino < 1399 && agin.length > 4) ||
                  (widthLiveCasino < 1099 && agin.length > 3) ||
                  (widthLiveCasino < 991 && agin.length > 4) ||
                  (widthLiveCasino < 767 && agin.length > 3) ||
                  (widthLiveCasino < 480 && agin.length > 2)
              "
            >
              <button @click="$refs.agin.previous()" class="prev-button">
                <i class="fas fa-chevron-left"></i>
              </button>
              <button @click="$refs.agin.next()" class="next-button">
                <i class="fas fa-chevron-right"></i>
              </button>
            </div>
          </div>
          <div class="game-good-slide">
            <vueper-slides
              class="no-shadow"
              :visible-slides="6"
              slide-multiple
              :gap="2"
              :slide-ratio="1 / 4"
              :arrows="false"
              :bullets="false"
              :touchable="false"
              ref="agin"
              :breakpoints="{
                1599: { visibleSlides: 5, slideMultiple: 5 },
                1399: { visibleSlides: 4, slideMultiple: 4 },
                1099: { visibleSlides: 3, slideMultiple: 3 },
                991: { visibleSlides: 4, slideMultiple: 4 },
                767: { visibleSlides: 3, slideMultiple: 3 },
                480: { visibleSlides: 2, slideMultiple: 2, gap: '2' },
              }"
            >
              <vueper-slide v-for="(item, i) in agin" :key="i">
                <template #content>
                  <ItemGame
                    :item="item"
                    @chooseGameGetUrl="chooseGameGetUrl"
                    :type="'live-casino-item'"
                  />
                </template>
              </vueper-slide>
            </vueper-slides>
          </div>
        </div>
        <!-- evolution -->
        <div
          class="game-good"
          v-if="showGameDisplay === 1 && evolution && evolution.length > 0"
        >
          <div class="game-good-title">
            <div class="tit-hot">
              <i class="fas fa-anchor"></i>
              <div class="cont">Evolution</div>
              <span class="total" v-if="evolution && evolution.length > 0">{{
                evolution.length
              }}</span>
            </div>
            <div
              class="page-wrap"
              v-if="
                (evolution &&
                  evolution.length > 0 &&
                  widthLiveCasino > 1599 &&
                  evolution.length > 6) ||
                  (widthLiveCasino < 1599 && evolution.length > 5) ||
                  (widthLiveCasino < 1399 && evolution.length > 4) ||
                  (widthLiveCasino < 1099 && evolution.length > 3) ||
                  (widthLiveCasino < 991 && evolution.length > 4) ||
                  (widthLiveCasino < 767 && evolution.length > 3) ||
                  (widthLiveCasino < 480 && evolution.length > 2)
              "
            >
              <button @click="$refs.evolution.previous()" class="prev-button">
                <i class="fas fa-chevron-left"></i>
              </button>
              <button @click="$refs.evolution.next()" class="next-button">
                <i class="fas fa-chevron-right"></i>
              </button>
            </div>
          </div>
          <div class="game-good-slide">
            <vueper-slides
              class="no-shadow"
              :visible-slides="6"
              slide-multiple
              :gap="2"
              :slide-ratio="1 / 4"
              :arrows="false"
              :bullets="false"
              :touchable="false"
              ref="evolution"
              :breakpoints="{
                1599: { visibleSlides: 5, slideMultiple: 5 },
                1399: { visibleSlides: 4, slideMultiple: 4 },
                1099: { visibleSlides: 3, slideMultiple: 3 },
                991: { visibleSlides: 4, slideMultiple: 4 },
                767: { visibleSlides: 3, slideMultiple: 3 },
                480: { visibleSlides: 2, slideMultiple: 2, gap: '2' },
              }"
            >
              <vueper-slide v-for="(item, i) in evolution" :key="i">
                <template #content>
                  <ItemGame
                    :item="item"
                    @chooseGameGetUrl="chooseGameGetUrl"
                    :type="'live-casino-item'"
                  />
                </template>
              </vueper-slide>
            </vueper-slides>
          </div>
        </div>
        <!-- sky_game -->
        <div
          class="game-good"
          v-if="showGameDisplay === 1 && sky_game && sky_game.length > 0"
        >
          <div class="game-good-title">
            <div class="tit-new">
              <i class="fab fa-galactic-republic"></i>
              <div class="cont">SKY GAME</div>
              <span class="total" v-if="sky_game && sky_game.length > 0">{{
                sky_game.length
              }}</span>
            </div>
            <div
              class="page-wrap"
              v-if="
                (sky_game &&
                  sky_game.length > 0 &&
                  widthLiveCasino > 1599 &&
                  sky_game.length > 6) ||
                  (widthLiveCasino < 1599 && sky_game.length > 5) ||
                  (widthLiveCasino < 1399 && sky_game.length > 4) ||
                  (widthLiveCasino < 1099 && sky_game.length > 3) ||
                  (widthLiveCasino < 991 && sky_game.length > 4) ||
                  (widthLiveCasino < 767 && sky_game.length > 3) ||
                  (widthLiveCasino < 480 && sky_game.length > 2)
              "
            >
              <button @click="$refs.gameNew.previous()" class="prev-button">
                <i class="fas fa-chevron-left"></i>
              </button>
              <button @click="$refs.gameNew.next()" class="next-button">
                <i class="fas fa-chevron-right"></i>
              </button>
            </div>
          </div>
          <div class="game-good-slide">
            <vueper-slides
              class="no-shadow"
              :visible-slides="6"
              slide-multiple
              :gap="2"
              :slide-ratio="1 / 4"
              :arrows="false"
              :bullets="false"
              :touchable="false"
              ref="gameNew"
              :breakpoints="{
                1599: { visibleSlides: 5, slideMultiple: 5 },
                1399: { visibleSlides: 4, slideMultiple: 4 },
                1099: { visibleSlides: 3, slideMultiple: 3 },
                991: { visibleSlides: 4, slideMultiple: 4 },
                767: { visibleSlides: 3, slideMultiple: 3 },
                480: { visibleSlides: 2, slideMultiple: 2, gap: '2' },
              }"
            >
              <vueper-slide v-for="(item, i) in sky_game" :key="i">
                <template #content>
                  <ItemGame :item="item" @chooseGameGetUrl="chooseGameGetUrl" />
                </template>
              </vueper-slide>
            </vueper-slides>
          </div>
        </div>
        <!-- SA GAME -->
        <template v-if="false">
          <div
            class="game-good"
            v-if="showGameDisplay === 1 && sa_game && sa_game.length > 0"
          >
            <div class="game-good-title">
              <div class="tit">
                <i class="fab fa-galactic-republic"></i>
                <div class="cont">SA GAME</div>
                <span class="total" v-if="sa_game && sa_game.length > 0">{{
                  sa_game.length
                }}</span>
              </div>
              <div
                class="page-wrap"
                v-if="
                  (sa_game &&
                    sa_game.length > 0 &&
                    widthLiveCasino > 1599 &&
                    sa_game.length > 6) ||
                    (widthLiveCasino < 1599 && sa_game.length > 5) ||
                    (widthLiveCasino < 1399 && sa_game.length > 4) ||
                    (widthLiveCasino < 1099 && sa_game.length > 3) ||
                    (widthLiveCasino < 991 && sa_game.length > 4) ||
                    (widthLiveCasino < 767 && sa_game.length > 3) ||
                    (widthLiveCasino < 480 && sa_game.length > 2)
                "
              >
                <button @click="$refs.gameGood.previous()" class="prev-button">
                  <i class="fas fa-chevron-left"></i>
                </button>
                <button @click="$refs.gameGood.next()" class="next-button">
                  <i class="fas fa-chevron-right"></i>
                </button>
              </div>
            </div>
            <div class="game-good-slide">
              <vueper-slides
                class="no-shadow"
                :visible-slides="6"
                slide-multiple
                :gap="2"
                :slide-ratio="1 / 4"
                :arrows="false"
                :bullets="false"
                :touchable="false"
                ref="gameGood"
                :breakpoints="{
                  1599: { visibleSlides: 5, slideMultiple: 5 },
                  1399: { visibleSlides: 4, slideMultiple: 4 },
                  1099: { visibleSlides: 3, slideMultiple: 3 },
                  991: { visibleSlides: 4, slideMultiple: 4 },
                  767: { visibleSlides: 3, slideMultiple: 3 },
                  480: { visibleSlides: 2, slideMultiple: 2, gap: '2' },
                }"
              >
                <vueper-slide v-for="(item, i) in sa_game" :key="i">
                  <template #content>
                    <ItemGame
                      :item="item"
                      @chooseGameGetUrl="chooseGameGetUrl"
                    />
                  </template>
                </vueper-slide>
              </vueper-slides>
            </div>
          </div>
        </template>
        <!-- List game -->
        <div class="gameList">
          <div class="gameList-title">
            <div class="tit">
              <i class="fab fa-d-and-d"></i>
              <div class="cont">
                {{
                  categoryKeyChoose === 'sa_game'
                    ? 'SA GAME'
                    : categoryKeyChoose === 'sky_game'
                    ? 'SKY GAME'
                    : categoryKeyChoose === 'agin'
                    ? 'Agin'
                    : categoryKeyChoose === 'wm555'
                    ? 'wm555'
                    : categoryKeyChoose === 'Evo'
                    ? 'Evolution'
                    : $t('Message.275')
                }}
              </div>
              <span class="total">
                {{ getLengthListGameByCategory(categoryKeyChoose) }}
              </span>
            </div>
          </div>
          <div
            class="gameList-list"
            v-if="
              listGame.game &&
                listGame.game.all &&
                listGame.game.all.length > 0 &&
                listGameShow
            "
          >
            <div class="gameItem" v-for="(item, i) in listGameShow" :key="i">
              <ItemGame
                :item="item"
                @chooseGameGetUrl="chooseGameGetUrl"
                :type="'live-casino-all'"
              />
            </div>
            <div class="gameList-btnMore-box">
              <div class="gameList-btnMore">
                <div class="btnMore-info">
                  <span class="current mr-1"
                    >{{
                      listGameShow && listGameShow.length > 0
                        ? listGameShow.length
                        : 0
                    }} </span
                  >/<span class="ml-1 text-white">
                    {{ getLengthListGameByCategory(categoryKeyChoose) }}</span
                  >
                </div>
                <button
                  class="btnMore-load"
                  @click="loadMore(returnListGameByCategory(categoryKeyChoose))"
                  v-if="
                    getLengthListGameByCategory(categoryKeyChoose) >
                      listGameShow.length
                  "
                >
                  <div class="button-inner">{{ $t('LiveCasino.141') }}</div>
                </button>
              </div>
            </div>
          </div>
        </div>
        <!-- Partner -->
        <!-- <div class="partner">
          <div class="partner-title">
            <div class="tit">
              <i class="fab fa-galactic-republic"></i>
              <div class="cont">Game Providers</div>
              <span class="total">23</span>
            </div>
            <div class="page-wrap">
              <button @click="$refs.partner.previous()" class="prev-button">
                <i class="fas fa-chevron-left"></i>
              </button>
              <button @click="$refs.partner.next()" class="next-button">
                <i class="fas fa-chevron-right"></i>
              </button>
            </div>
          </div>
          <div class="partner-slide">
            <vueper-slides
              class="no-shadow"
              :visible-slides="7"
              slide-multiple
              :gap="0"
              :slide-ratio="1 / 4"
              :arrows="false"
              :bullets="false"
              :touchable="false"
              ref="partner"
              :breakpoints="{
                1499: { visibleSlides: 6, slideMultiple: 6 },
                1399: { visibleSlides: 5, slideMultiple: 5 },
                1299: { visibleSlides: 4, slideMultiple: 4 },
                991: { visibleSlides: 5, slideMultiple: 5 },
                767: { visibleSlides: 4, slideMultiple: 4 },
                480: { visibleSlides: 3, slideMultiple: 3 },
              }"
            >
              <vueper-slide v-for="(item, i) in 10" :key="i">
                <template #content>
                  <div class="partner-item">
                    <a href="#"></a>
                  </div>
                </template>
              </vueper-slide>
            </vueper-slides>
          </div>
        </div> -->
      </div>
    </div>
  </div>
</template>

<script>
  import ItemGame from '@/components/game/ItemGame.vue';
  import { VueperSlides, VueperSlide } from 'vueperslides';
  import 'vueperslides/dist/vueperslides.css';
  import { mapState, mapMutations, mapGetters } from 'vuex';
  export default {
    components: { VueperSlides, VueperSlide, ItemGame },
    data() {
      return {
        showListChooseGame: false,
        widthLiveCasino: null,
        listGameShow: [],
        showGameDisplay: 1,
        categoryChoose: '',
        categoryKeyChoose: 'all',
        showIframe: false,
        iframe: {
          src: '',
        },
      };
    },
    computed: {
      ...mapGetters({
        listGame: 'dashboard/ListGame',
      }),
      sa_game() {
        if (
          this.listGame.game &&
          this.listGame.game.sa_game &&
          this.listGame.game.sa_game.length > 0
        )
          return this.listGame.game.sa_game;
      },
      sky_game() {
        if (
          this.listGame.game &&
          this.listGame.game.sky_game &&
          this.listGame.game.sky_game.length > 0
        )
          return this.listGame.game.sky_game;
      },
      agin() {
        if (
          this.listGame.game &&
          this.listGame.game.agin &&
          this.listGame.game.agin.length > 0
        )
          return this.listGame.game.agin;
      },
      wm555() {
        if (
          this.listGame.game &&
          this.listGame.game.wm555 &&
          this.listGame.game.wm555.length > 0
        )
          return this.listGame.game.wm555;
      },
      evolution() {
        if (
          this.listGame.game &&
          this.listGame.game.Evolution &&
          this.listGame.game.Evolution.length > 0
        )
          return this.listGame.game.Evolution;
      },
      allGame() {
        if (
          this.listGame.game &&
          this.listGame.game.all &&
          this.listGame.game.all.length > 0
        )
          return this.listGame.game.all;
      },
      categoryGame() {
        if (
          this.listGame &&
          this.listGame.category &&
          this.listGame.category.length > 0
        )
          return this.listGame.category;
      },
    },
    methods: {
      handleResize() {
        this.widthLiveCasino = window.innerWidth;
      },
      loadMore(paramsArr) {
        let arr = [];
        let lenght = this.listGameShow.length + 6;
        if (lenght > paramsArr.length) {
          let lenght2 = paramsArr.length - this.listGameShow.length;
          lenght = this.listGameShow.length + lenght2;
        }
        if (this.listGameShow && this.listGameShow.length > 0) {
          for (let i = 0; i < lenght; i++) {
            arr.push(paramsArr[i]);
          }
        }
        this.listGameShow = arr;
      },
      showGame(paramsArr) {
        let arr = [];
        let lenght = paramsArr.length;
        if (paramsArr && paramsArr.length > 0 && paramsArr.length >= 12)
          lenght = 12;
        else lenght = paramsArr.length;
        if (paramsArr && paramsArr.length > 0) {
          for (let i = 0; i < lenght; i++) {
            arr.push(paramsArr[i]);
          }
        }
        this.listGameShow = arr;
      },
      getListGameByCategory(category) {
        if (this.listGame && this.listGame.game) {
          if (
            category === 'all' &&
            this.listGame.game.all &&
            this.listGame.game.all.length > 0
          ) {
            this.showGame(this.listGame.game.all);
            return this.listGame.game.all;
          }
          if (
            category === 'sa_game' &&
            this.listGame.game.sa_game &&
            this.listGame.game.sa_game.length > 0
          ) {
            this.showGame(this.listGame.game.sa_game);
            return this.listGame.game.sa_game;
          }
          if (
            category === 'sky_game' &&
            this.listGame.game.sky_game &&
            this.listGame.game.sky_game.length > 0
          ) {
            this.showGame(this.listGame.game.sky_game);
            return this.listGame.game.sky_game;
          }
          if (
            category === 'agin' &&
            this.listGame.game.agin &&
            this.listGame.game.agin.length > 0
          ) {
            this.showGame(this.listGame.game.agin);
            return this.listGame.game.agin;
          }
          if (
            category === 'Evo' &&
            this.listGame.game.Evolution &&
            this.listGame.game.Evolution.length > 0
          ) {
            this.showGame(this.listGame.game.Evolution);
            return this.listGame.game.Evolution;
          }
          if (
            category === 'wm555' &&
            this.listGame.game.wm555 &&
            this.listGame.game.wm555.length > 0
          ) {
            this.showGame(this.listGame.game.wm555);
            return this.listGame.game.wm555;
          }
        }
      },
      returnListGameByCategory(category) {
        if (this.listGame && this.listGame.game) {
          if (
            category === 'all' &&
            this.listGame.game.all &&
            this.listGame.game.all.length > 0
          ) {
            return this.listGame.game.all;
          }
          if (
            category === 'sa_game' &&
            this.listGame.game.sa_game &&
            this.listGame.game.sa_game.length > 0
          ) {
            return this.listGame.game.sa_game;
          }
          if (
            category === 'sky_game' &&
            this.listGame.game.sky_game &&
            this.listGame.game.sky_game.length > 0
          ) {
            return this.listGame.game.sky_game;
          }
          if (
            category === 'agin' &&
            this.listGame.game.agin &&
            this.listGame.game.agin.length > 0
          ) {
            return this.listGame.game.agin;
          }
          if (
            category === 'Evo' &&
            this.listGame.game.Evolution &&
            this.listGame.game.Evolution.length > 0
          ) {
            return this.listGame.game.Evolution;
          }
          if (
            category === 'wm555' &&
            this.listGame.game.wm555 &&
            this.listGame.game.wm555.length > 0
          ) {
            return this.listGame.game.wm555;
          }
        }
      },
      getLengthListGameByCategory(category) {
        if (this.listGame && this.listGame.game) {
          if (
            category === 'all' ||
            (category === '' &&
              this.listGame.game.all &&
              this.listGame.game.all.length > 0)
          )
            return this.listGame.game.all.length;
          if (
            category === 'sa_game' &&
            this.listGame.game.sa_game &&
            this.listGame.game.sa_game.length > 0
          )
            return this.listGame.game.sa_game.length;
          if (
            category === 'sky_game' &&
            this.listGame.game.sky_game &&
            this.listGame.game.sky_game.length > 0
          )
            return this.listGame.game.sky_game.length;
          if (
            category === 'agin' &&
            this.listGame.game.agin &&
            this.listGame.game.agin.length > 0
          )
            return this.listGame.game.agin.length;
          if (
            category === 'Evo' &&
            this.listGame.game.Evolution &&
            this.listGame.game.Evolution.length > 0
          )
            return this.listGame.game.Evolution.length;
          if (
            category === 'wm555' &&
            this.listGame.game.wm555 &&
            this.listGame.game.wm555.length > 0
          )
            return this.listGame.game.wm555.length;
        }
      },
      chooseGameGetUrl(url) {
        this.iframe.src = url;
        this.iframe.status = true;
        this.$store.commit('getGame', this.iframe);
      },
      changeShowIframeParent(status) {
        this.showIframe = status;
      },
    },
    async created() {
      window.addEventListener('resize', this.handleResize);
      this.handleResize();
      await this.$store.dispatch('dashboard/req_getListGame');
    },
    watch: {
      allGame: {
        handler(newVal) {
          if (newVal) this.showGame(newVal);
        },
      },
    },
    destroyed() {
      window.removeEventListener('resize', this.handleResize);
    },
  };
</script>
<style lang="scss">
  .game-good-slide {
    .vueperslides__parallax-wrapper {
      padding-bottom: 20% !important;
      @media (max-width: 1599px) {
        padding-bottom: 23% !important;
      }
      @media (max-width: 1399px) {
        padding-bottom: 31% !important;
      }
      @media (max-width: 1099px) {
        padding-bottom: 41% !important;
      }
      @media (max-width: 991px) {
        padding-bottom: 30% !important;
      }
      @media (max-width: 767px) {
        padding-bottom: 40% !important;
      }
      @media (max-width: 480px) {
        padding-bottom: 60% !important;
      }
    }
  }
  .partner-slide {
    .vueperslides__parallax-wrapper {
      padding-bottom: 5% !important;
      @media (max-width: 1699px) {
        padding-bottom: 7% !important;
      }
      @media (max-width: 1499px) {
        padding-bottom: 10% !important;
      }
      @media (max-width: 480px) {
        padding-bottom: 15% !important;
      }
    }
  }
</style>
<style scoped lang="scss">
  .position-content-container {
    width: 100%;
    display: flex;
    align-items: center;
    flex-direction: column;
    position: relative;
    z-index: 2;
    padding-bottom: 20px;
    @media (max-width: 480px) {
      padding: 0px 10px 30px 10px;
    }
    &:after {
      // content: '';
      position: fixed; /* stretch a fixed position to the whole screen */
      top: 0;
      height: 100vh; /* fix for mobile browser address bar appearing disappearing */
      left: 0;
      right: 0;
      z-index: -1; /* needed to keep in the background */
      background: url('~@/assets/images/bg.jpg') center center;
      -webkit-background-size: cover;
      -moz-background-size: cover;
      -o-background-size: cover;
      background-size: cover;
      -webkit-box-shadow: inset 0 0 0 2000px rgba(31, 35, 60, 0.8);
      box-shadow: inset 0 0 0 2000px rgba(31, 35, 60, 0.8);
    }
    &::before {
      position: absolute;
      content: '';
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      //   background-color: rgba(0, 0, 0, 0.1);
      z-index: 0;
    }
    .position {
      width: 100%;
      max-width: 1368px;
      position: relative;
      padding: 1.25rem;
      @media (max-width: 480px) {
        padding: 0px;
      }
      .position-header {
        display: flex;
        -webkit-box-pack: justify;
        justify-content: space-between;
        -webkit-box-align: center;
        align-items: center;
        margin-top: 22px;
        .slots-path {
          background-image: linear-gradient(
            to right,
            rgba(105, 179, 1, 0.4),
            rgba(85, 89, 102, 0.4) 25%,
            rgba(85, 89, 102, 0.4)
          );
          display: inline-flex;
          padding: 0px 2rem;
          height: 2.5rem;
          line-height: 2.5rem;
          border-radius: 1.25rem;
          @media (max-width: 480px) {
            padding: 0px 1rem;
            height: 1.5rem;
            font-size: 9px;
          }
          .slot {
            display: flex;
            -webkit-box-align: center;
            align-items: center;
            .active {
              color: rgb(255, 255, 255);
              font-weight: 800;
            }
          }
        }
        .select {
          width: 200px;
          margin-left: 1.875rem;
          color: rgba(153, 164, 176, 0.6);
          @media (max-width: 480px) {
            width: 150px;
            height: 1.5rem;
            font-size: 11px;
            margin-left: 0.5rem;
          }
          .select-trigger {
            position: relative;
            display: flex;
            -webkit-box-align: center;
            align-items: center;
            overflow: hidden;
            height: 100%;
            cursor: pointer;
            padding: 0px 1.25rem;
            user-select: none;
            border-radius: 1.25rem;
            background-color: rgba(49, 52, 60, 0.5);
            .provider-label {
              width: 90%;
              white-space: nowrap;
              overflow: hidden;
              text-overflow: ellipsis;
            }
            .arrow {
              width: 2.5rem;
              height: 100%;
              display: flex;
              -webkit-box-align: center;
              align-items: center;
              -webkit-box-pack: center;
              justify-content: center;
              position: absolute;
              right: 0px;
              top: 0px;
              svg {
                font-size: 0.625rem;
                transition: transform 0.5s cubic-bezier(0.36, 0.66, 0.04, 1) 0s;
                color: rgba(153, 164, 176, 0.6);
              }
            }
          }
          .listTypeGame {
            position: absolute;
            top: 85px;
            width: 200px;
            padding: 10px 10px;
            border-radius: 1.25rem;
            background-color: rgb(27, 29, 33);
            z-index: 10;
            @media (max-width: 480px) {
              top: 50px;
              width: 150px;
            }
            @media (max-width: 375px) {
              width: 140px;
            }
            p {
              &:hover {
                background-color: rgb(45, 48, 53);
                color: #fff;
                width: 100%;
                padding: 5px 10px;
                cursor: pointer;
                border-radius: 20px;
                margin-bottom: 5px;
              }
              font-size: 14px;
              display: flex;
              justify-content: space-between;
              align-items: center;
              @media (max-width: 480px) {
                font-size: 12px;
              }
              &.activeType {
                border: 1px solid rgb(123, 197, 20);
                border-radius: 20px;
                padding: 5px 10px;
              }
              span {
                color: rgb(123, 197, 20);
                &.active {
                  background-color: rgb(93, 160, 0);
                  width: 8px;
                  height: 8px;
                  border-radius: 50%;
                  position: relative;
                  &::after {
                    content: '';
                    position: absolute;
                    width: 1.125rem;
                    height: 1.125rem;
                    left: -0.3125rem;
                    top: -0.3125rem;
                    background-color: rgba(144, 155, 135, 0.15);
                    border-radius: 1.125rem;
                  }
                }
              }
            }
          }
        }
      }
      .position-content {
        width: 100%;
        .game-good {
          width: 100%;
          .game-good-title {
            width: 100%;
            margin: 28px 0px 12px 10px;
            display: flex;
            -webkit-box-align: center;
            align-items: center;
            color: rgb(153, 164, 176);
            @media (max-width: 480px) {
              margin: 28px 0px 12px 0px;
            }
            .tit {
              color: rgb(255, 255, 255);
              font-size: 1rem;
              font-weight: 800;
              display: flex;
              -webkit-box-align: center;
              align-items: center;
              -webkit-box-pack: center;
              justify-content: center;
              @media (max-width: 480px) {
                font-size: 12px;
              }
              .cont {
                @media (max-width: 375px) {
                  white-space: nowrap;
                  overflow: hidden;
                  text-overflow: ellipsis;
                  width: 100px;
                }
              }
              svg {
                margin-right: 0.5rem;
                color: rgb(247, 140, 0);
              }
              .total {
                color: rgb(152, 255, 8);
                margin-left: 0.875rem;
              }
            }
            .tit-new {
              color: rgb(255, 255, 255);
              font-size: 1rem;
              font-weight: 800;
              display: flex;
              -webkit-box-align: center;
              align-items: center;
              -webkit-box-pack: center;
              justify-content: center;
              @media (max-width: 480px) {
                font-size: 12px;
              }
              .cont {
                @media (max-width: 375px) {
                  white-space: nowrap;
                  overflow: hidden;
                  text-overflow: ellipsis;
                  width: 100px;
                }
              }
              svg {
                margin-right: 0.5rem;
                color: rgb(255, 0, 234);
              }
              .total {
                color: rgb(152, 255, 8);
                margin-left: 0.875rem;
              }
            }
            .tit-hot {
              color: rgb(255, 255, 255);
              font-size: 1rem;
              font-weight: 800;
              display: flex;
              -webkit-box-align: center;
              align-items: center;
              -webkit-box-pack: center;
              justify-content: center;
              @media (max-width: 480px) {
                font-size: 12px;
              }
              .cont {
                @media (max-width: 375px) {
                  white-space: nowrap;
                  overflow: hidden;
                  text-overflow: ellipsis;
                  width: 100px;
                }
              }
              svg {
                margin-right: 0.5rem;
                color: rgb(239, 104, 26);
              }
              .total {
                color: rgb(152, 255, 8);
                margin-left: 0.875rem;
              }
            }
            .page-wrap {
              flex: 1 1 auto;
              display: flex;
              -webkit-box-pack: end;
              justify-content: flex-end;
              -webkit-box-align: center;
              align-items: center;
              .prev-button {
                width: 2.125rem;
                height: 2rem;
                background-color: rgb(30, 32, 36);
                border-top-left-radius: 1rem;
                border-bottom-left-radius: 1rem;
                margin: 0px;
                display: flex;
                -webkit-box-align: center;
                align-items: center;
                -webkit-box-pack: center;
                justify-content: center;
                color: rgba(153, 164, 176, 0.6);
                border: none;
                margin-right: 5px;
                @media (max-width: 480px) {
                  width: 1.5rem;
                  height: 1.5rem;
                }
                &:hover {
                  color: #fff;
                  background-color: rgba(75, 72, 72, 0.719);
                }
              }
              .next-button {
                width: 2.125rem;
                height: 2rem;
                background-color: rgb(30, 32, 36);
                border-top-right-radius: 1rem;
                border-bottom-right-radius: 1rem;
                display: flex;
                -webkit-box-align: center;
                align-items: center;
                -webkit-box-pack: center;
                justify-content: center;
                color: rgba(153, 164, 176, 0.6);
                border: none;
                @media (max-width: 480px) {
                  width: 1.5rem;
                  height: 1.5rem;
                }
                &:hover {
                  color: #fff;
                  background-color: rgba(75, 72, 72, 0.719);
                }
              }
            }
          }
        }
        .gameList {
          width: 100%;
          .gameList-title {
            width: 100%;
            margin: 28px 0px 12px 10px;
            display: flex;
            -webkit-box-align: center;
            align-items: center;
            color: rgb(153, 164, 176);
            .tit {
              color: rgb(255, 255, 255);
              font-size: 1rem;
              font-weight: 800;
              display: flex;
              -webkit-box-align: center;
              align-items: center;
              -webkit-box-pack: center;
              justify-content: center;
              @media (max-width: 480px) {
                font-size: 12px;
              }
              .cont {
                @media (max-width: 375px) {
                  white-space: nowrap;
                  overflow: hidden;
                  text-overflow: ellipsis;
                  width: 100px;
                }
              }
              svg {
                margin-right: 0.5rem;
                color: rgb(152, 255, 8);
              }
              .total {
                color: rgb(152, 255, 8);
                margin-left: 0.875rem;
              }
            }
          }
          .gameList-list {
            margin-top: 32px;
            display: flex;
            flex-wrap: wrap;
            justify-content: flex-start;
            width: 100%;
            .gameItem {
              flex: 1 1 auto;
              width: 15%;
              margin-right: 0.8%;
              margin-left: 0.8%;
              max-width: 210px;
              @media (max-width: 1699px) {
                width: 18%;
                max-width: 18%;
              }
              @media (max-width: 1499px) {
                width: 23%;
                max-width: 23%;
              }
              @media (max-width: 1099px) {
                width: 31%;
                max-width: 31%;
              }
              @media (max-width: 991px) {
                width: 23%;
                max-width: 23%;
              }
              @media (max-width: 767px) {
                width: 31%;
                max-width: 31%;
              }
              @media (max-width: 480px) {
                width: 48%;
                max-width: 48%;
              }
            }
            .gameList-btnMore-box {
              width: 100%;
              display: flex;
              justify-content: center;
              .gameList-btnMore {
                // margin: 1.5625rem auto;
                width: 12.5rem;
                .btnMore-info {
                  display: flex;
                  -webkit-box-align: center;
                  align-items: center;
                  -webkit-box-pack: center;
                  justify-content: center;
                  margin-bottom: 1.0625rem;
                  color: #fff;
                  .current {
                    font-weight: bold;
                  }
                }
                .btnMore-load {
                  box-shadow: rgb(29 34 37 / 10%) 0px 4px 8px 0px;
                  background-color: #5da000;
                  background-image: conic-gradient(
                    from 1turn,
                    #458802,
                    #7bc514
                  );
                  border-radius: 6.25rem;
                  width: 10rem;
                  height: 3rem;
                  line-height: 3rem;
                  text-align: center;
                  font-weight: 400;
                  margin: 0px auto;
                  color: #fff;
                  font-weight: bold;
                  box-shadow: rgb(29 34 37 / 10%) 0px 4px 8px 0px;
                  display: block;
                  border-radius: 6.25rem;
                  cursor: pointer;
                  transition: transform 0.2s cubic-bezier(0.36, 0.66, 0.04, 1)
                    0s;
                  border: none;
                  &:hover {
                    color: #fff;
                    background-color: rgb(92, 92, 94);
                  }
                  .button-inner {
                    display: flex;
                    -webkit-box-align: center;
                    align-items: center;
                    -webkit-box-pack: center;
                    justify-content: center;
                    width: 100%;
                    height: 100%;
                  }
                }
              }
            }
          }
        }
        .partner {
          width: 100%;
          .partner-title {
            width: 100%;
            margin: 28px 0px 12px 10px;
            display: flex;
            -webkit-box-align: center;
            align-items: center;
            color: rgb(153, 164, 176);
            .tit {
              color: rgb(255, 255, 255);
              font-size: 1rem;
              font-weight: 800;
              display: flex;
              -webkit-box-align: center;
              align-items: center;
              -webkit-box-pack: center;
              justify-content: center;
              @media (max-width: 480px) {
                font-size: 12px;
              }
              .cont {
                @media (max-width: 375px) {
                  white-space: nowrap;
                  overflow: hidden;
                  text-overflow: ellipsis;
                  width: 100px;
                }
              }
              svg {
                margin-right: 0.5rem;
                color: rgb(255, 189, 9);
              }
              .total {
                color: rgb(152, 255, 8);
                margin-left: 0.875rem;
              }
            }
            .page-wrap {
              flex: 1 1 auto;
              display: flex;
              -webkit-box-pack: end;
              justify-content: flex-end;
              -webkit-box-align: center;
              align-items: center;
              .prev-button {
                width: 2.125rem;
                height: 2rem;
                background-color: rgb(30, 32, 36);
                border-top-left-radius: 1rem;
                border-bottom-left-radius: 1rem;
                margin: 0px;
                display: flex;
                -webkit-box-align: center;
                align-items: center;
                -webkit-box-pack: center;
                justify-content: center;
                color: rgba(153, 164, 176, 0.6);
                border: none;
                margin-right: 5px;
                &:hover {
                  color: #fff;
                  background-color: rgba(75, 72, 72, 0.719);
                }
              }
              .next-button {
                width: 2.125rem;
                height: 2rem;
                background-color: rgb(30, 32, 36);
                border-top-right-radius: 1rem;
                border-bottom-right-radius: 1rem;
                display: flex;
                -webkit-box-align: center;
                align-items: center;
                -webkit-box-pack: center;
                justify-content: center;
                color: rgba(153, 164, 176, 0.6);
                border: none;
                &:hover {
                  color: #fff;
                  background-color: rgba(75, 72, 72, 0.719);
                }
              }
            }
          }
          .partner-slide {
            .partner-item {
              border-radius: 20px;
              a {
                display: block;
                background-image: url('~@/assets/images/home/partner.png');
                background-repeat: no-repeat;
                background-color: rgb(49, 52, 58);
                height: 3.5rem;
                background-size: cover;
                background-position: center;
                cursor: pointer;
                margin-right: 3px;
                border-radius: 10px;
                @media (max-width: 1299px) {
                  height: 2.5rem;
                }
              }
            }
          }
        }
      }
    }
    .gameIframe {
      width: 100%;
      height: calc(100vh - 4rem - 70px);
      position: relative;
      z-index: 3;
      .gameIframe-back {
        cursor: pointer;
        margin-bottom: 10px;
        img {
          width: 30px;
          height: 30px;
          &:hover {
            filter: brightness(150%);
          }
        }
        &:hover {
          color: #fff;
        }
      }
      iframe {
        width: 100%;
        height: 100%;
      }
    }
  }
</style>
